// # Custom style re-style of GitHub gist embeds.
// * The GitHub-provided gists embedding Javascript uses https://github.githubassets.com/assets/gist-embed-f710d4a619fe68a1be4400197b4c3132.css
// * minimal-mistake colors are defined in _sass/minimal-mistakes/_variables.scss (or in a theme override) and used in _syntax.scss. The colors are $base00....
//
// ## Attempt #1
// First tried to use https://github.com/aweary/gist.scss
// and config it to use the current jekyll theme variables. However this project is outdated and it could not override everything. Config used
//$gist: (
  //background: $base00,
  //color: $base05,
  //border: none,
  //padding: 3em,
 //// Max colors should be len($classes)==8
  //colors: $base05 $base0b $base03 $base04 $base06 $base07 $base08 $base09,
  //line-numbers-bg: $base00,
  //line-numbers-color: $base04,
  //line-numbers-border: none,
  //meta-display: none,
//);
//
// ## Attempt #2
// Use more up-to-date base from https://codepen.io/j_holtslander/pen/EjGabg here below and modified with minimal-mitakes color variables using browser CSS inspector tool.


.gist {
	display:block!important;
	position: relative!important;
	margin: 15px 0 !important;
	left: -30px!important; /* Nudge left outside of container */
	width: calc(100% + 60px)!important; /* Fill 100% of the parent container with an extra 30px on each side */
	overflow: hidden!important;
}

.gist-file{
	border: none !important;
}

.gist-data{
	background-color: $base00 !important;
	padding: 15px 5px !important;
	border: 0 !important;
	border-bottom: none !important;
	border-radius: 0px;
}

.gist .highlight {
	background-color: $base00 !important;
}

.gist .gist-meta{
	background-color: #fff !important;
	color: $base05 !important;
	border: 0px !important;
	border-radius: 0px;
	visibility: hidden!important;
}

.gist-meta a {
	display: block;
	visibility: visible !important;
	font-weight: 100!important;
}

.gist-meta a:first-child {
	float: none!important;
}

.gist-meta a:first-child:first-letter {
	text-transform: uppercase!important;
}

.gist-meta a:nth-child(2) {
	display: none !important;
}

.gist-meta a:nth-child(3) {
	display: none !important;
}

.gist-meta a:first-child:after {
	content: " on GitHub Gists" !important;
}

.blob-num {
	border: solid #464741!important;
	border-width: 0 1px 0 0!important;
}


.blob-code-inner {
	color:$base08!important;
	font-size:1.3em!important;
}

.js-line-number {color:$base04 !important;}

.pl-ent {color:#fc1f70!important;}

.pl-e {color:#a4e402!important;}

.pl-c {color:$base04!important;}

.pl-s, .pl-pds {color:$base0b!important;}


.gist .pl-s .pl-s1, .gist .pl-smi {
	color: $base08 !important;
}

.gist .pl-c1, .gist .pl-s .pl-v {
	color: $base05 !important;
}
