/* ==========================================================================
   NAVIGATION ACTIVE PAGE #navactivepage
   ========================================================================== */


.nav-active-page {
	/* Use a color from minimal-mistakes/_sass/minimal-mistakes/skins/ */
	/*background-color: $primary-color;*/
	/* #appearancetoggle Nope, the primary-color of the default theme does not work well as bg here. Thus let's define a variable in skin_dark.css and main.css */
	background-color: $appearancetoggle-nav-bg-color;
	border-radius: 0.25em 0.5em;
}
