// #appearancetoggle
// Reference: https://www.w3schools.com/howto/howto_css_switch.asp

 /* The switch - the box around the slider */
.appearanceToggleSwitch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 27px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Hide default HTML checkbox */
.appearanceToggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider ball */
.appearanceSwitchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.appearanceSwitchSlider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .appearanceSwitchSlider {
  background-color: #2196F3;
}

input:focus + .appearanceSwitchSlider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .appearanceSwitchSlider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.appearanceSwitchSlider.round {
  border-radius: 20px;
}

.appearanceSwitchSlider.round:before {
  border-radius: 50%;
}

/* Push switch side buttons up on the same level as the slider. Needed to do the fact that it's in a <li> in the <nav> for some reason */
.appearanceSwitchIcon {
      	vertical-align: middle;
      	padding-bottom: 20px;

}
